import { mapStatus, Task, TaskCategory, TaskDto, TaskType } from './task';
import { Injectable } from '@angular/core';
import { TaskActionService } from './task-action.service';
import { throwError } from 'rxjs/internal/observable/throwError';
import { mapProgressItems } from '../progress-items/progress-items-mapper';

declare const window: { pendo: any | undefined };

export const mapPaymentTask = (taskDto: TaskDto): Task => {
    return {
        title: 'Enable payments',
        headline: 'Enable payments from guests',
        description: `Configure your Favrit account to handle payments.`,
        actionButton: {
            text: 'Start configuration',
            blockedText: 'You need to add your legal business name before you can start this task.',
            onClick: () => {}
        },
        type: taskDto.type,
        navigationLink: `/location/${taskDto.locationId}/payments`,
        pendingIconName: 'enablePayments',
        completedIconName: 'completed',
        completedDescription: `Payments are enabled.`,
        includeInAccountStatus: true,
        category: TaskCategory.PAYMENT,
        progressItems: mapProgressItems(taskDto.progressItems),
        status: mapStatus(taskDto.status),
        prerequisiteTasks: taskDto.prerequisiteTasks,
        locationId: taskDto.locationId
    };
};

@Injectable({
    providedIn: 'root'
})
export class TaskMapperService {
    constructor(private taskService: TaskActionService) {}

    mapToTask(taskDto: TaskDto): Task {
        const completedIconName = 'completed';
        switch (taskDto.type) {
            case TaskType.ADD_LEGAL_BUSINESS_NAME:
                return {
                    headline: 'Add legal business name',
                    description: `
                        We need to know the legal name of your business in order to enable payments from guests in your Favrit account.
                        <br/>
                        You can use the interactive guide by pressing “Show me how”.`,

                    actionButton: {
                        text: 'Show me how',
                        onClick: (): void => {
                            window.pendo.showGuideById('p4ThVJgnLDpGIec_VaWeNuAvOXw');
                        }
                    },
                    type: taskDto.type,
                    category: TaskCategory.PAYMENT,
                    pendingIconName: 'businessName',
                    completedIconName,
                    completedDescription: `Legal business name is registered.`,
                    includeInAccountStatus: taskDto.includeInAccountStatus,
                    progressItems: mapProgressItems(taskDto.progressItems),
                    status: mapStatus(taskDto.status),
                    prerequisiteTasks: taskDto.prerequisiteTasks,
                    locationId: taskDto.locationId
                };
            case TaskType.ENABLE_PAYMENTS:
                return mapPaymentTask(taskDto);
            case TaskType.ENABLE_PAYOUTS:
                return {
                    title: 'Enable withdrawals',
                    headline: 'Enable withdrawals from Favrit',
                    description: `To enable withdrawals from Favrit you need to verify your company with our payment service provider following the checks below.`,
                    actionButton: {
                        text: 'Start task',
                        blockedText: 'You need to enable payments from guests before you can start this task',
                        onClick: async (): Promise<void> => await this.taskService.startKyc(taskDto.locationId)
                    },
                    type: taskDto.type,
                    navigationLink: `/location/${taskDto.locationId}/payments`,
                    pendingIconName: 'enableWithdrawals',
                    completedIconName,
                    completedDescription: `Company is verified and withdrawals are enabled.`,
                    includeInAccountStatus: taskDto.includeInAccountStatus,
                    category: TaskCategory.PAYMENT,
                    progressItems: mapProgressItems(taskDto.progressItems),
                    status: mapStatus(taskDto.status),
                    prerequisiteTasks: taskDto.prerequisiteTasks,
                    locationId: taskDto.locationId
                };
            case TaskType.SET_UP_MENU:
                return {
                    headline: 'Set up menu',
                    description: `
                        Before you can go live you should add some items to your menu so that your customers have something to buy from you.
                        <br/>
                        You can go to the “Menu” page in the left hand navigation menu or use the interactive guide by pressing “Show me how”.
                    `,
                    actionButton: {
                        text: 'Show me how',
                        onClick: (): void => {
                            window.pendo.showGuideById('f-fofyNGMm2al1pBd_s_lyn5U78');
                        }
                    },
                    type: taskDto.type,
                    pendingIconName: 'setupMenu',
                    completedIconName,
                    completedDescription: `Menu has been set up.`,
                    includeInAccountStatus: taskDto.includeInAccountStatus,
                    category: TaskCategory.COMPANY,
                    progressItems: mapProgressItems(taskDto.progressItems),
                    status: mapStatus(taskDto.status),
                    prerequisiteTasks: taskDto.prerequisiteTasks,
                    locationId: taskDto.locationId
                };
            case TaskType.UPLOAD_MENU_HEADER_IMAGE:
                return {
                    headline: 'Upload menu header image',
                    description: `
                        The menu header image will be displayed at the top of the webpage where your customers can browse your menu.
                        <br/><strong>Tip:</strong> Add a great image here that will motivate guests to choose your location.
                        It might be a picture of your location from outside, the great atmosphere indoors or some of your best-looking food or beverages.
                    `,
                    actionButton: {
                        text: 'Show me how',
                        onClick: (): void => {
                            window.pendo.showGuideById('TMwy26MzTG0PghgxIMwb5QKBMD4');
                        }
                    },
                    type: taskDto.type,
                    pendingIconName: 'uploadImage',
                    completedIconName,
                    completedDescription: `Menu header image has been uploaded.`,
                    includeInAccountStatus: taskDto.includeInAccountStatus,
                    category: TaskCategory.COMPANY,
                    progressItems: mapProgressItems(taskDto.progressItems),
                    status: mapStatus(taskDto.status),
                    prerequisiteTasks: taskDto.prerequisiteTasks,
                    locationId: taskDto.locationId
                };
            case TaskType.UPLOAD_LISTING_IMAGE:
                return {
                    headline: 'Upload image for the frontpage of favrit.com',
                    description: `
                        We will use this image (alongside your location name and address) to represent your location of favrit.com.
                        When guests are searching for where to go this is their first impression, so make it count!
                        <br/><strong>Tip:</strong> Add a great image here that will motivate guests to choose your location.
                        It might be a picture of your location from outside, the great atmosphere indoors or some of your best-looking food or beverages.
                    `,
                    actionButton: {
                        text: 'Show me how',
                        onClick: (): void => {
                            window.pendo.showGuideById('lctp_4BqUxMwHvEBjbIbwKI7Oko');
                        }
                    },
                    type: taskDto.type,
                    pendingIconName: 'uploadImage',
                    completedIconName,
                    completedDescription: `Listing image has been uploaded.`,
                    includeInAccountStatus: taskDto.includeInAccountStatus,
                    category: TaskCategory.COMPANY,
                    progressItems: mapProgressItems(taskDto.progressItems),
                    status: mapStatus(taskDto.status),
                    prerequisiteTasks: taskDto.prerequisiteTasks,
                    locationId: taskDto.locationId
                };
            case TaskType.PUBLISH_LOCATION:
                return {
                    title: 'Publish location',
                    headline: 'Publish location',
                    description: `Publishing makes the webpage for your location live. This means guests can find your menu if they use the QR code or the URL.`,
                    actionButton: {
                        text: 'Publish location',
                        confirmText: 'Are you sure you want to publish this location?',
                        blockedText:
                            'You need to set up your menu and upload a menu image before you can start this task',
                        onClick: (): void => {
                            this.taskService.publishLocation(taskDto.locationId);
                        }
                    },
                    type: taskDto.type,
                    navigationLink: `/location/${taskDto.locationId}/organization`,
                    pendingIconName: 'publish',
                    completedIconName,
                    includeInAccountStatus: taskDto.includeInAccountStatus,
                    category: TaskCategory.COMPANY,
                    progressItems: mapProgressItems(taskDto.progressItems),
                    status: mapStatus(taskDto.status),
                    pendingImageSrc: 'assets/images/cogwheels_icon.svg',
                    pendingHeadline: 'We are processing your request to publish your location',
                    pendingDescription: `It might take up to <strong>24 hours</strong> for your location to go live. We will send you an email once everything is up and running!`,
                    completedImageSrc: 'assets/images/checkmark_icon.svg',
                    completedHeadline: 'Your location is published!',
                    completedDescription: `The webpage for your location is now <strong>live</strong> and is accessible by QR-code and URL!`,
                    completedActionButton: {
                        text: 'Unpublish location',
                        confirmText: 'Are you sure you want to unpublish this location?',
                        onClick: () => {
                            this.taskService.unpublishLocation(taskDto.locationId);
                        }
                    },
                    prerequisiteTasks: taskDto.prerequisiteTasks,
                    locationId: taskDto.locationId
                };
            case TaskType.LIST_LOCATION:
                return {
                    title: 'List location',
                    headline: 'List location on favrit.com',
                    description: `When you list your location we will show it on favrit.com, where more than 650.000 guests are looking for where to go!`,
                    actionButton: {
                        text: 'List location',
                        confirmText: 'Are you sure you want to list this location?',
                        blockedText:
                            'You need to publish your location and upload a frontpage image before you can start this task',
                        onClick: (): void => {
                            this.taskService.listLocation(taskDto.locationId);
                        }
                    },
                    type: taskDto.type,
                    progressItems: mapProgressItems(taskDto.progressItems),
                    status: mapStatus(taskDto.status),
                    navigationLink: `/location/${taskDto.locationId}/organization`,
                    includeInAccountStatus: taskDto.includeInAccountStatus,
                    pendingImageSrc: 'assets/images/cogwheels_icon.svg',
                    pendingHeadline: 'We are processing your request to list your location',
                    pendingDescription: `It might take up to <strong>24 hours</strong> for your location to go live. We will send you an email once everything is up and running!`,
                    completedImageSrc: 'assets/images/checkmark_icon.svg',
                    completedHeadline: 'Your location is listed!',
                    completedDescription: `Your location is currently listed on the frontpage of Favrit, where more than <strong>150.000 guests</strong> are looking for where to go next!`,
                    completedActionButton: {
                        text: 'Unlist location',
                        confirmText: 'Are you sure you want to unlist this location?',
                        onClick: (): void => {
                            this.taskService.unlistLocation(taskDto.locationId);
                        }
                    },
                    prerequisiteTasks: taskDto.prerequisiteTasks,
                    locationId: taskDto.locationId
                };
            default:
                throwError(`Task type ${taskDto.type} is unknown!`);
        }
    }
}
