import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    NbAuthModule,
    NbOAuth2ResponseType,
    NbAuthOAuth2JWTToken,
    NbTokenStorage,
    NbPasswordAuthStrategy,
    NbOAuth2ClientAuthMethod
} from '@nebular/auth';

import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AnalyticsService } from './utils';
import { UserData } from './data/users';
import { UserService } from './mock/users.service';
import { MockDataModule } from './mock/mock-data.module';
import { WS_BASE } from '../shared/constants/urls';
import FavritSSOStrategy from '../shared/auth/FavritSSOStrategy';
import { FavritSSOTokenStorage } from 'app/shared/auth/FavritSSOTokenStorage';
import { FavritSSOToken } from 'app/shared/auth/FavritSSOToken';
import { getConfig } from 'app/shared/auth/SSOConfig';
import { environment } from 'environments/environment';

const DATA_SERVICES = [{ provide: UserData, useClass: UserService }];

export class NbSimpleRoleProvider extends NbRoleProvider {
    getRole() {
        // here you could provide any role based on any auth flow
        return observableOf('guest');
    }
}

export const PasswordAuthStrategySettings = {
    name: 'email',
    token: {
        class: NbAuthOAuth2JWTToken,
        // eslint-disable-next-line @typescript-eslint/camelcase
        getter: (r, res) => ({ access_token: res.body.accessToken, refresh_token: res.body.refreshToken }),
        setter: () => undefined
    },
    // baseEndpoint: WS_BASE,
    baseEndpoint: WS_BASE,
    login: {
        endpoint: '/public/v2/login',
        method: 'post'
    },
    resetPass: {
        endpoint: '/public/v1/password-reset',
        method: 'post'
    },
    requestPass: {
        endpoint: '/public/v1/password-reset',
        method: 'post'
    },
    refreshToken: {
        endpoint: '/public/v1/refresh-token-admin',
        method: 'POST'
    }
};
const PasswordAuthStrategy = NbPasswordAuthStrategy.setup(PasswordAuthStrategySettings);

const SSOConfig = getConfig(environment.environment);
const OAuthStrategy = FavritSSOStrategy.setup({
    name: 'sso',
    clientId: 'admin',
    clientAuthMethod: NbOAuth2ClientAuthMethod.NONE,

    token: {
        class: FavritSSOToken,
        grantType: 'refresh_token'
        // eslint-disable-next-line @typescript-eslint/camelcase
    },
    authorize: {
        requireValidToken: false,
        endpoint: SSOConfig.authenticationServiceUrl,
        responseType: NbOAuth2ResponseType.TOKEN,
        redirectUri: SSOConfig.redirectUrl
    },
    refresh: {
        endpoint: WS_BASE + '/public/v2/refresh-token-admin',
        requireValidToken: true
    }
});

export const NB_CORE_PROVIDERS = [
    ...MockDataModule.forRoot().providers,
    ...DATA_SERVICES,
    ...NbAuthModule.forRoot({
        strategies: [OAuthStrategy, PasswordAuthStrategy],
        forms: {
            requestPassword: {
                redirectDelay: 8000
            },
            validation: {
                newPassword: {
                    required: true
                }
            }
        }
    }).providers,
    NbSecurityModule.forRoot({
        accessControl: {
            guest: {
                view: '*'
            },
            user: {
                parent: 'guest',
                create: '*',
                edit: '*',
                remove: '*'
            }
        }
    }).providers,

    {
        provide: NbRoleProvider,
        useClass: NbSimpleRoleProvider
    },
    {
        provide: NbTokenStorage,
        useClass: FavritSSOTokenStorage
    },
    AnalyticsService
];

@NgModule({
    imports: [CommonModule],
    exports: [NbAuthModule],
    declarations: []
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [...NB_CORE_PROVIDERS]
        };
    }
}
