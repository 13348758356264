import { environment } from '../../../environments/environment';

export const WS_BASE = environment.server_url;
export const APP_BASE = environment.app_base;
export const ONBOARDING_BASE = environment.onboarding;
export const PAYMENT_BASE = environment.payment;
export const PRODUCT_LIB_BASE = environment.productlib;
export const ACCOUNTING_BASE = environment.accounting_service;
export const FORTNOX_AUTHORIZATION_LINK = environment.fortnox_authorization_link;
export const VISMA_AUTHORIZATION_LINK = environment.visma_authorization_link;
export const VISMA_E_EKONOMI_AUTHORIZATION_LINK = environment.visma_e_ekonomi_authorization_link;
export const FIKEN_AUTHORIZATION_LINK = environment.fiken_authorization_link;
export const SOFTRIG_AUTHORIZATION_LINK = environment.softrig_authorization_link;
export const SAGE_AUTHORIZATION_LINK = environment.sage_authorization_link;
export const QUICKBOOKS_AUTHORIZATION_LINK = environment.quickbooks_authorization_link;
export const XERO_AUTHORIZATION_LINK = environment.xero_authorization_link;
export const PLANDAY_AUTHORIZATION_LINK = environment.planday_authorization_link;
export const AMPLITUDE_API_KEY = environment.amplitude_api_key;
export const AMPLITUDE_API_KEY_REPORTING = environment.amplitude_api_key_reporting;
export const ACCOUNTING_API_BASE = environment.accounting_api_service;
export const EPOS_URL = environment.epos_url;
