import {
    TRANSLOCO_LOADER,
    Translation,
    TranslocoLoader,
    TRANSLOCO_CONFIG,
    translocoConfig,
    TranslocoModule
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { LocaleId } from 'app/shared/interfaces/locale';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string) {
        return this.http.get<Translation>(`../../assets/i18n/${lang}.json`);
    }
}

const nbNOFormatConfig = {
    currency: {
        minimumFractionDigits: 0
    }
};

@NgModule({
    imports: [
        TranslocoModule,
        TranslocoLocaleModule.forRoot({
            localeConfig: {
                localeBased: {
                    'nb-NO': nbNOFormatConfig
                }
            },
            langToLocaleMapping: {
                [LocaleId.NB_NO]: 'nb-NO',
                [LocaleId.SV_SE]: 'sv-SE',
                [LocaleId.DA_DK]: 'da-DK',
                [LocaleId.EN_GB]: 'en-GB',
                [LocaleId.FI_FI]: 'fi-FI'
            }
        })
    ],
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: [LocaleId.NB_NO, LocaleId.SV_SE, LocaleId.DA_DK, LocaleId.EN_GB, LocaleId.FI_FI],
                defaultLang: LocaleId.EN_GB,
                fallbackLang: LocaleId.EN_GB,
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: environment.production,
                missingHandler: {
                    allowEmpty: true // Set to false to output the missing translation key instead of empty string
                }
            })
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
    ]
})
export class TranslocoRootModule {}
